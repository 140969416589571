import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";



export default function Brand() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Brands">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Brands"
                    Subheader="Brands"
                    bgimg={`${rpdata?.gallery?.[15]}`}
                />
                <div className="md:h-[70vh] h-auto py-10 md:py-0 flex">
                    <div className={`w-4/5 mx-auto flex flex-col flex-wrap justify-center items-center gap-4 pb-5`}>
                        <h2>Our Product Brands</h2>
                        <div
                            className="text-center flex md:flex-row flex-col">

                            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Brand%20Landscape%20tools%2Fbrand-2.png?alt=media&token=95b14846-a2b8-4b44-9103-615219f26d4e" alt="Not Found" width={'250px'} />
                            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Brand%20Landscape%20tools%2Fbrand-3.png?alt=media&token=e28c7f59-ab88-412f-8648-74d296dc8ec6" alt="Not Found" width={'250px'} />
                            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Brand%20Landscape%20tools%2Fbrand-4.png?alt=media&token=486a5400-98ad-428d-93f2-519cdb1c3fa6" alt="Not Found" width={'250px'} />
                            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Brand%20Landscape%20tools%2Fbrand-5.png?alt=media&token=966ba5d0-dac5-44da-853f-2efbd8874e92" alt="Not Found" width={'250px'} />
                            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Brand%20Landscape%20tools%2Fbrand-6.png?alt=media&token=ce9e3a3e-7a33-4a5a-afa6-611ce5e2cb1e" alt="Not Found" width={'250px'} />
                        </div>

                    </div>
                </div>

            </div>
        </BaseLayout >
    )
}

